import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import renderHTML from 'react-render-html'


class Details extends Component{
    state = {
        detailsData: '',
        readMore: true,
        loading: true,
        saved: false,
        savedCoupon: null,
        offerPurshaseInfo: null,
    }
    handleReadMore = () => {
        this.setState({
            readMore: !this.state.readMore
        })
    }
    componentDidMount = () => {
        axios.get(`https://relycircle.com/api/listings/information/${this.props.listingId}`)
        .then(res => {
            this.setState({
                detailsData: res.data.listing,
                // loading: false
            })
        });
        this.loadSavedCouponsInfo();
    }

    async loadSavedCouponsInfo() {
        let userToken =  localStorage.getItem('auth');
        this.setState({ loading: true });
        try {
            const res = await axios.get('https://relycircle.com/api/offers/myPurchases', {
                headers: {
                    'X-Auth-Token': `${userToken}`
                }
            });
            const coupon = this.props.item.Coupon;
            const offerPurshaseInfo = await axios.get("https://relycircle.com/api/offers/couponPurchaseInfo/"+coupon.id,  {
                headers: {
                    'X-Auth-Token': `${userToken}`
                }
            });
            this.setState({ saved: true, offerPurshaseInfo: offerPurshaseInfo.data.OfferPurchase });
        }catch (e) {
            console.log({ e });
        } finally {
            this.setState({ loading: false });
        }
    }

    handleMapLink = (lat,lng) => {
        if( (navigator.platform.indexOf("iPhone") != -1) 
            || (navigator.platform.indexOf("iPod") != -1)
            || (navigator.platform.indexOf("iPad") != -1))
             window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination="+lat+","+lng);
        else
             window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination="+lat+","+lng);
    }
    handleSaveCoupon = () =>{
        localStorage.setItem('isLoaded', '');
        if(!localStorage.getItem('auth')){
            document.getElementById("loggedinRedirect").click()            
        } else {

            let userToken =  localStorage.getItem('auth')
            axios.get('https://relycircle.com/api/offers/myPurchases', {
                headers: {
                    'X-Auth-Token': `${userToken}`
                }
            }).then((res) => {
                //console.log('saved coupons - ', res)
                this.setState({
                    savedCoupons: res.data.offerPurchases
                })
                let savedCheck = ''
                if(this.state.savedCoupons.length > 0){
                    savedCheck = this.state.savedCoupons.find((item)=>{
                        return item.Coupon.id == this.props.item.Coupon.id
                    })
                } 
                //console.log('saved item - ', savedCheck.Coupon)

                if(savedCheck ? savedCheck.Coupon.id : '' == this.props.item.Coupon.id) {                    
                    alert('This Coupon has already saved !')
                    this.setState({
                        saved: true
                    })
                } else {
                    var formdata = new FormData();
                    formdata.append("data[Coupon][id]", this.props.item.Coupon.id);
                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow',
                        headers: {
                            'X-Auth-Token': `${userToken}`
                        }
                    };
                    fetch(`https://relycircle.com/api/offers/saveCoupon/${this.props.listingId}/${this.props.referCode}`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        var resp = JSON.parse(result)
                        this.loadSavedCouponsInfo();
                    })
                    .catch(error => console.log('error', error));
                }

            }).catch((res) => {
                console.log(res)
            });


            
        }
    }
    showVoucherCode() {
        const { offerPurshaseInfo } = this.state;;
        if (!offerPurshaseInfo) {
            return null;
        }
        return (<div className="coupon-barcode-block">
            <h3>
                {offerPurshaseInfo.offer_usage_code_display}
            </h3>
            <img style={{ height: "50px", width: "90px" }} src={offerPurshaseInfo.barcode_base64} />
        </div>);
    }
    render(){
        let details = this.props.details.listing
        //console.log('coupon detail - ', this.props.item.Coupon.id)
        return(
            <>
            <Link to={'/login'} id="loggedinRedirect"></Link>
            <Link to={'/my-coupons?couponId='+this.props.item.Coupon.id} id="gotoSavedCoupon"></Link>
                <div className="detail-header coupons-header">
                    <span onClick={this.props.close} className="back-btn"><i className="icon-back"></i></span>
                    {/* <div className="search-btn"><i className="icon-nav"><img src={require('../../assets/images/menu.svg')} /></i></div> */}
                    <div className="title-box"><h4>Coupons</h4></div>
                </div>
                <div className="detail-full">
                    <div className="coupon-item">
                        <div class="listing-img coupon-img" style={{backgroundImage: 'url(https://relycircle.com'+this.props.item.Coupon.image_thumbs.medium+')'}}></div>
                        <div className="coupon-info">{this.props.item.Coupon.description}</div>
                    </div>
                    <div className="brief-full">
                        <div className="inner">
                            <div className="brief">
                                {renderHTML(this.props.item.Coupon.terms.replace("\r\n", '<br />'))}
                            </div>
                            <div className="title-box"><h4>{details.Listing.title}</h4></div>
                        </div>
                    </div>
                    <div className="detail-contact">
                        <div className="inner">
                            <ul className="detail-contact-list">
                                <li>
                                    <div className="contact-item flex">
                                        <div className="contact-item-left flex-auto"><i className="icon-location"></i> {details.Listing.address_1+' '+details.Listing.zip_code_info.city+', '+details.Listing.zip_code_info.state+' '+details.Listing.zip_code_info.zip_code}</div>
                                        <div className="contact-item-right"><a onClick={() => this.handleMapLink(details.Listing.lat, details.Listing.lng)} className="link">Go To Maps <i className="icon-link"></i></a></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="contact-item flex">
                                        <div className="contact-item-left flex-auto contact-webiste-url"><i className="icon-website"></i> {details.ListingContactInformation.website}</div>
                                        <div className="contact-item-right"><a href={details.ListingContactInformation.website} target="_blank" className="link">Go To Website <i className="icon-link"></i></a></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="contact-item flex">
                                        <div className="contact-item-left flex-auto"><i className="icon-phone"></i> {details.ListingContactInformation.phone}</div>
                                        <div className="contact-item-right"><a href={"tel:"+details.ListingContactInformation.phone} className="link">Call Phone <i className="icon-link"></i></a></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="save-button-full">
                    {this.state.saved ? this.showVoucherCode() : <span onClick={this.handleSaveCoupon} className={`btn-main ${this.state.loading ? 'disabled' : ''}`}>
                        {this.state.loading ? 'Loading...' : 'Save'}
                      </span>}
                </div>
            </>
        )
    }
}
export default Details

import React from 'react';
import Listing from './container/listing'
import Details from './container/details'
import Coupon from './container/coupon'
import { BrowserRouter as Router, Route, BrowserRouter} from 'react-router-dom';
import Login from './container/login'
import Register from './container/register'
import Profile from './container/profile'
import MyCoupon from './container/profile/my-coupons'

import './assets/css/style.css'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" component={Listing} />
        <Route exact path="/business-detail/:id" component={Details} />
        <Route exact path="/coupons/:id" component={Coupon} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/my-coupons" component={MyCoupon} />
      </BrowserRouter> 
    </div>
  );
}

export default App;

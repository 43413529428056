import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';
import '../../../node_modules/react-owl-carousel2/src/owl.carousel.css';
import axios from 'axios'
import renderHTML from 'react-render-html'
import Navbar from '../../components/navbar'
import Search from '../../components/search'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Details extends Component{
    state = {
        detailsData: '',
        reviews: '',
        readMore: true,
        loading: true,
        loadingReview: true,
        galleryVisible: false,
        showNav: false,
        user: window.location.search ? window.location.search.split('=')[1] : 0,
        reviewTop: [],
        reviewAll: [],
        photoIndex: 0,
        isOpen: false
    }
    handleNavbar = () => {
        this.setState({
            showNav: !this.state.showNav
        })
    }
    handleReadMore = () => {
        this.setState({
            readMore: !this.state.readMore
        })
    }
    componentDidMount = () => {
        axios.get(`https://relycircle.com/api/listings/information/${this.props.match.params.id}`)
        .then(res => {
            this.setState({
                detailsData: res.data.listing,
                loading: false,

            })
        })
        axios.get(`https://relycircle.com/api/reviews/business/${this.props.match.params.id}`)
        .then(res => {
            this.setState({
                reviews: res.data.reviews,
                loadingReview: false
            })
            if(this.state.user > 0){
                let reviewTop = this.state.reviews.find(item => {
                    return item.User.id == this.state.user
                })
                let reviewAll = this.state.reviews.filter(item => {
                    return item.User.id != this.state.user
                })
                this.setState({
                    reviewTop: [reviewTop],
                    reviewAll: reviewAll,
                })
            } else {
                this.setState({
                    reviewTop: [],
                    reviewAll: res.data.reviews,
                })
            }

        })
        window.scrollTo(0,0)
        
    }
    handleMapLink = (lat,lng) => {
        // If it's an iPhone..
        if( (navigator.platform.indexOf("iPhone") != -1) 
            || (navigator.platform.indexOf("iPod") != -1)
            || (navigator.platform.indexOf("iPad") != -1))
             window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination="+lat+","+lng);
        else
             window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination="+lat+","+lng);
    }
    handleGalleryBox = () => {
        this.setState({
            galleryVisible: !this.state.galleryVisible
        })
    }
    handleImageView = (i) => {
        this.setState({
            photoIndex: i,
            isOpen: true
        })
    }
    render(){
        let item = this.state.detailsData;
        let review = this.state.reviews;

        const options = {
            items: 1,
            nav: true,
            rewind: true
        };
        const { photoIndex, isOpen } = this.state;
        return(
            
            <>
            {
                this.state.showNav ? (
                    <>
                    <nav className="navbar-full">
                        <div className="navbar-inner">
                            <Search />
                            <Navbar />
                        </div>
                    </nav>
                    
                    <div className="page-overlay" onClick={this.handleNavbar}></div>
                    </>
                ) : null
            }
            <main className="main-full">
                {
                    this.state.loading ? (<div className="loader"></div>) : (
                        <>
                        <div className="detail-header">
                            <span onClick={() => window.history.back()} className="back-btn"><i className="icon-back"></i></span>
                            <div className="search-btn" onClick={this.handleNavbar}>
                                <i className="icon-nav">
                                    {this.state.showNav ? <img src={require('../../assets/images/close2.svg')} /> : <img src={require('../../assets/images/menu.svg')} /> }
                                </i>
                            </div>
                            <div className="title-box">
                                <h4>{item.Listing.business_name}</h4>
                                <div className="location"><i className="icon-location"></i> {item.Listing.zip_code_info.city+', '+item.Listing.zip_code_info.state}</div>
                            </div>
                        </div>
                        <div className="detail-full">
                            <div className="listing-item detail-img-box">
                                <OwlCarousel options={options}>
                                    {
                                        item.ListingAsset.map((img,i)=>{
                                            return <div key={i} className="slide-image-item" onClick={() => this.handleImageView(i)}><img src={'https://relycircle.com'+img.thumbs.large} /></div> 
                                        })
                                    }
                                </OwlCarousel>
                                <a href="https://z8x94.app.goo.gl/u9DC" target="_blank" className="detail-tag"><i className="icon-tag"></i></a>
                            </div>
                                {
                                    console.log('images ', item.ListingAsset[0].thumbs.large)
                                }
                            {isOpen && (
                                <Lightbox
                                    mainSrc={'https://relycircle.com'+item.ListingAsset[photoIndex].thumbs.large}
                                    nextSrc={'https://relycircle.com'+item.ListingAsset[(photoIndex + 1) % item.ListingAsset.length].thumbs.large}
                                    prevSrc={'https://relycircle.com'+item.ListingAsset[(photoIndex + item.ListingAsset.length - 1) % item.ListingAsset.length].thumbs.large}
                                    onCloseRequest={() => this.setState({ isOpen: false })}
                                    onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + item.ListingAsset.length - 1) % item.ListingAsset.length,
                                    })
                                    }
                                    onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex: (photoIndex + 1) % item.ListingAsset.length,
                                    })
                                    }
                                />
                                )}
                                
                            <div className="detail-meta flex">
                                <div className="detail-meta-item"><span>{item.Listing.review_count}</span> Recommendations </div>
                                <div className="detail-meta-item"><span>0</span> Circle Recommendations </div>
                                <div className="detail-meta-item"><span>{item.Listing.coupons_count}</span> Offers / Coupons </div>
                            </div>
                            <div className="brief-full">
                                <div className="inner">
                                    <div className="brief">
                                        {item.Listing.description.length > 100 ? (
                                            this.state.readMore ? (
                                                <p>
                                                    {renderHTML(item.Listing.description.substring(0,100) + '... ')}
                                                    <span className="more-link" onClick={this.handleReadMore}>see more [+]</span>
                                                </p>
                                            ) : (
                                                <p>
                                                    {renderHTML(item.Listing.description + ' ')}
                                                    <span className="more-link" onClick={this.handleReadMore}>see less [-]</span>
                                                </p>
                                            )
                                        ) : (
                                            <p>
                                                {renderHTML(item.Listing.description + ' ')}
                                            </p>     
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="detail-contact">
                                <div className="inner">
                                    <ul className="detail-contact-list">
                                        <li>
                                            <div className="contact-item flex">
                                                <div className="contact-item-left flex-auto"><i className="icon-location"></i> {item.Listing.address_1+' '+item.Listing.zip_code_info.city+', '+item.Listing.zip_code_info.state+' '+item.Listing.zip_code_info.zip_code}</div>
                                                <div className="contact-item-right"><a onClick={() => this.handleMapLink(item.Listing.lat, item.Listing.lng)} className="link">Go To Maps <i className="icon-link"></i></a></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-item flex">
                                                <div className="contact-item-left flex-auto contact-webiste-url"><i className="icon-website"></i> {item.ListingContactInformation.website}</div>
                                                <div className="contact-item-right"><a href={item.ListingContactInformation.website} target="_blank" className="link">Go To Website <i className="icon-link"></i></a></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-item flex">
                                                <div className="contact-item-left flex-auto"><i className="icon-phone"></i> {item.ListingContactInformation.phone}</div>
                                                <div className="contact-item-right"><a href={"tel:"+item.ListingContactInformation.phone} className="link">Call Phone <i className="icon-link"></i></a></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="detail-offer">
                                <div className="inner">
                                    {
                                        review.length ? (
                                            this.state.reviewTop.map((review,index) => {
                                                return(
                                                    <div className="detail-offer-item" key={index}>
                                                        <div className="detail-offer-top flex items-center">
                                                            <div className="detail-offer-left">
                                                                <div className="thumb-box">
                                                                    <div className="thumb"><img src={'https://relycircle.com'+review.User.avatar_thumbs.small} /></div>
                                                                    <a href="https://z8x94.app.goo.gl/u9DC" target="_blank" className="add-icon-box"><i className="icon-add">+</i></a>
                                                                </div>
                                                            </div>
                                                            <div className="thumb-info flex-auto">
                                                                <div className="user-title">{review.User.display_name}</div>
                                                                {
                                                                    review.User.zip_code ? (
                                                                        <div className="user-des">{review.User.zip_code.city}, {review.User.zip_code.state_code}</div>
                                                                    ) : ('')
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="detail-offer-brief">
                                                            <div className="brief">
                                                                <p>{review.Review.content}</p>
                                                            </div>
                                                            <div className="btn-outer text-right">
                                                                <Link to={'/coupons/'+item.Listing.id} className="btn-main">View Offers</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : ('')
                                    }
                                    {
                                        review.length ? (
                                            this.state.reviewAll.map((review,index) => {
                                                return(
                                                    <div className="detail-offer-item" key={index}>
                                                        <div className="detail-offer-top flex items-center">
                                                            <div className="detail-offer-left">
                                                                <div className="thumb-box">
                                                                    <div className="thumb"><img src={'https://relycircle.com'+review.User.avatar_thumbs.small} /></div>
                                                                    <a href="https://z8x94.app.goo.gl/u9DC" target="_blank" className="add-icon-box"><i className="icon-add">+</i></a>
                                                                </div>
                                                            </div>
                                                            <div className="thumb-info flex-auto">
                                                                <div className="user-title">{review.User.display_name}</div>
                                                                {
                                                                    review.User.zip_code ? (
                                                                        <div className="user-des">{review.User.zip_code.city}, {review.User.zip_code.state_code}</div>
                                                                    ) : ('')
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="detail-offer-brief">
                                                            <div className="brief">
                                                                <p>{review.Review.content}</p>
                                                                {console.log('review.Review > ', review.Review)}
                                                            </div>
                                                            <div className="btn-outer text-right">
                                                                <Link to={'/coupons/'+item.Listing.id+'?reviewId='+review.Review.id} className="btn-main">View Offers</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : ('')
                                    }
                                </div>
                            </div>
                        </div>
                        </>
                    )
                }
            </main>
            </>
        )
    }
}
export default Details

import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'


const Navbar = () => {
    const [navitems, setNavitems] = useState([
        {id:'0', nav: 'Home', url: 'https://relycircle.com'},
        {id:'1', nav: 'About', url: 'https://relycircle.com/common/about'},
        {id:'2', nav: 'Benefits', url: 'https://relycircle.com/#benefits'},
        {id:'3', nav: 'How it works', url: 'https://relycircle.com/#how-it-works'},
        {id:'4', nav: 'FAQ', url: 'https://relycircle.com/common/faqs'},
        {id:'5', nav: 'Contact Us', url: 'https://relycircle.com/cms/contacts/add'},
        {id:'6', nav: 'Business Owners', url: 'https://relycircle.biz/'}
    ]);
    const [token, setToken] = useState(false)

    useEffect(() => {
        if(localStorage.getItem('auth')){
           setToken(true)        
        } else {
            setToken(false)
        }
    },[]);
    const handleLogout = () => {
        localStorage.setItem('auth', '')
        localStorage.setItem('fb_user', '')
        document.getElementById("loggedinRedirect").click()
    }

    return (
        <>
        <Link to={'/login'} id="loggedinRedirect"></Link>
        <ul className="nav-links">
            {
                navitems.map((item,i)=>{
                    return(
                        <li key={i}><a href={item.url} target="_blank">{item.nav}</a></li>
                    )
                })
            }
            {
                token ? (
                    <>
                        <li className="nav-devider"></li>
                        <li><Link to={'/profile'}><img src={require('../assets/images/user2.svg')} /> My Account</Link></li>
                        <li><Link to={'/my-coupons'}><img src={require('../assets/images/coupon.svg')} /> Saved Coupons</Link></li>
                    </>
                ) : ('')
            }
        </ul>
        <div className="nav-main-bottom">
            {
                token ? (
                    <div className="btn-login-box btn-logout-box">
                        <span onClick={handleLogout} className="btn-logout">LOGOUT</span>
                    </div>
                ) : (
                    <div className="btn-login-box">
                        <Link to={'/register'} className="btn"><i className="btn-icon"><img src={require('../assets/images/user.svg')} /></i> Sign Up</Link>
                        <Link to={'/login'} className="btn"><i className="btn-icon"><img src={require('../assets/images/user.svg')} /></i> Log In</Link>
                    </div>
                )
            }
        </div>
        </>
    )
}
export default Navbar
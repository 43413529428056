import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Navbar from '../../components/navbar'
import Search from '../../components/search'

import axios from 'axios'
import CouponDetails from './coupon-detail'

class MyCoupon extends Component{
    state = {
        showNav: false,
        loading: true,
        coupons: [],
        detail: false,
        detailItem: ''
    }
    handleNavbar = () => {
        this.setState({
            showNav: !this.state.showNav
        })
    }
    pageReload = () => {
        window.location.reload()
        document.getElementById('pageReload').disabled = true
        
    }
    componentDidMount = () => {
        if(!localStorage.getItem('auth')){
            document.getElementById("loggedinRedirect").click()            
        }
        let userToken =  localStorage.getItem('auth')
        axios.get('https://relycircle.com/api/offers/myPurchases', {
            headers: {
                'X-Auth-Token': `${userToken}`
            }
        }).then((res) => {
            console.log('res - ', res)
            this.setState({
                coupons: res.data.offerPurchases,
                loading: false
            })
        }).catch((res) => {
            console.log(res)
        });
        
        if (localStorage.getItem('isLoaded') !== 'yes') {
            localStorage.setItem('isLoaded', 'yes');
            
            window.location.reload();
        } else {
            return false
        }
        
    }
    handleDetail = (item) => {
        console.log('item', item)
        this.setState({
            detailItem: item,
            detail: true
        })
    }
    handleDetailClose = () => {
        this.setState({
            detailItem: '',
            detail: false
        })
    }
    render(){
        let coupons = this.state.coupons.filter((item)=>{
            return item.Coupon.id != window.location.search.split('=')[1]
        })
        let topCoupon = this.state.coupons.find((item)=>{
            return item.Coupon.id == window.location.search.split('=')[1]
        })
        return(
            <>  
            {
                this.state.showNav ? (
                    <>
                    <nav className="navbar-full">
                        <div className="navbar-inner">
                            <Search />
                            <Navbar />
                        </div>
                    </nav>
                    
                    <div className="page-overlay" onClick={this.handleNavbar}></div>
                    </>
                ) : null
            }
                <main className="main-full">
                    <Link to={'/login'} id="loggedinRedirect"></Link>
                    
                    <div className="coupon-page-container">
                        {/* <div className="profile-top">
                            <Link to={'/'} className="back-btn"><i className="icon-back"></i></Link>
                            <div className="search-btn" onClick={this.handleNavbar}>
                                <i className="icon-nav">
                                    {this.state.showNav ? <img src={require('../../assets/images/close2.svg')} /> : <img src={require('../../assets/images/menu.svg')} /> }
                                </i>
                            </div>
                        </div> */}
                        <div className="profile-info my-coupon-page">                            
                            <div className="">
                                <div className="detail-header coupons-header">
                                    <Link to={'/'} className="back-btn"><i className="icon-back"></i></Link>
                                    <div className="title-box" id="pageReload" onClick={() => this.pageReload()}>
                                        <h4>Saved Coupons</h4>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.loading ? (<div className="loader2"></div>) : (
                                    <div className="coupon-item-box">
                                        
                                        {
                                            coupons.length ? (
                                                <>
                                                {
                                                    topCoupon ? (
                                                        <div className="coupon-item-list flex" key={topCoupon.Coupon.id} onClick={() => this.handleDetail(topCoupon)}>
                                                            <div className="coupon-item-img" style={{backgroundImage: 'url(https://relycircle.com/'+topCoupon.Coupon.image+')'}}></div>
                                                            <div className="coupon-item-info flex-auto">
                                                                <div className="coupon-item-title">{topCoupon.Coupon.description}</div>    
                                                            </div>                                
                                                        </div>
                                                    ) : ('')
                                                }
                                                
                                                {
                                                    coupons.map((item,i)=>{
                                                        return(
                                                            <div className="coupon-item-list flex" key={i} onClick={() => this.handleDetail(item)}>
                                                                <div className="coupon-item-img" style={{backgroundImage: 'url(https://relycircle.com/'+item.Coupon.image+')'}}></div>
                                                                <div className="coupon-item-info flex-auto">
                                                                    <div className="coupon-item-title">{item.Coupon.description}</div>    
                                                                </div>                                
                                                            </div>
                                                        )
                                                    })
                                                }
                                                </>
                                                
                                            ) : (
                                                <div className="no-business">No Saves Coupons</div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>                        
                    </div>
                </main>
                {
                    this.state.detail ? (<div className="coupon-detail-full"><CouponDetails close={this.handleDetailClose} item={this.state.detailItem} /></div>) : ('')
                }
            </>
        )
    }
}

export default MyCoupon
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import renderHTML from 'react-render-html'


class CouponDetails extends Component{
    state = {
        detailsData: '',
        readMore: true,
        loading: true
    }
    handleReadMore = () => {
        this.setState({
            readMore: !this.state.readMore
        })
    }
    handleMapLink = (lat,lng) => {
        if( (navigator.platform.indexOf("iPhone") != -1) 
            || (navigator.platform.indexOf("iPod") != -1)
            || (navigator.platform.indexOf("iPad") != -1))
             window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination="+lat+","+lng);
        else
             window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination="+lat+","+lng);
    }
    render(){
        
        return(
            <>
                <main className="main-full">
                    <div className="coupon-page-container">                        
                        <div className="profile-info my-coupon-page">                            
                            <div className="">
                                <div className="detail-header coupons-header">
                                    <span className="back-btn" onClick={this.props.close}><i className="icon-back"></i></span>
                                    <div className="title-box">
                                        <h4>MY Coupons</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="detail-full">
                                <div className="coupon-item">
                                    <div className="listing-img coupon-img" style={{backgroundImage: 'url(https://relycircle.com'+this.props.item.Coupon.image_thumbs.medium+')'}}></div>
                                    <div className="coupon-info">{this.props.item.Coupon.description}</div>
                                </div>
                                <div className="brief-full">
                                    <div className="inner">
                                        <div className="brief">
                                            {renderHTML(this.props.item.Coupon.terms.replace("\r\n", '<br />'))}
                                        </div>
                                        <div className="title-box"><h4>{this.props.item.Listing.business_name}</h4></div>
                                    </div>
                                </div>
                                <div className="detail-contact">
                                    <div className="inner">
                                        <ul className="detail-contact-list">
                                            <li>
                                                <div className="contact-item flex">
                                                    <div className="contact-item-left flex-auto"><i className="icon-thumbsup"></i> Recommended by</div>
                                                    <div className="contact-item-right">
                                                        <div className="recommended-user flex">
                                                            {
                                                                this.props.item.User ? (
                                                                    <div className="thumb">
                                                                        {this.props.item.User.avatar_thumbs.small ? (
                                                                        <img src={'https://relycircle.com'+this.props.item.User.avatar_thumbs.small} alt={this.props.item.User.first_name} />
                                                                        ) : (
                                                                        <img src={require('../../assets/images/user-default.jpg')} alt={this.props.item.User.first_name} />
                                                                        )} 
                                                                    </div>
                                                                ) : ('')

                                                            }
                                                            {
                                                                this.props.item.User ? (
                                                                    <div className="recommended-user-info">
                                                                        <div className="review-name">{this.props.item.User.first_name+' '+this.props.item.User.last_name.substring(0, 1)+'.'}</div>
                                                                        <div className="review-location">
                                                                            {this.props.item.User.zip_code ? (
                                                                                this.props.item.User.zip_code.city+', '+this.props.item.User.zip_code.state
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                ) : ('')
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="contact-item flex">
                                                    <div className="contact-item-left flex-auto"><i className="icon-location"></i> {this.props.item.Listing.address_1+' '+this.props.item.Listing.zip_code_info.city+', '+this.props.item.Listing.zip_code_info.state+' '+this.props.item.Listing.zip_code_info.zip_code}</div>
                                                    <div className="contact-item-right"><a onClick={() => this.handleMapLink(this.props.item.Listing.lat, this.props.item.Listing.lng)} className="link">Go To Maps <i className="icon-link"></i></a></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="contact-item flex">
                                                    <div className="contact-item-left flex-auto contact-webiste-url"><i className="icon-website"></i> {this.props.item.ListingContactInformation.website}</div>
                                                    <div className="contact-item-right"><a href={this.props.item.ListingContactInformation.website} target="_blank" className="link">Go To Website <i className="icon-link"></i></a></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="contact-item flex">
                                                    <div className="contact-item-left flex-auto"><i className="icon-phone"></i> {this.props.item.ListingContactInformation.phone}</div>
                                                    <div className="contact-item-right"><a href={"tel:"+this.props.item.ListingContactInformation.phone} className="link">Call Phone <i className="icon-link"></i></a></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>                            
                        </div>                        
                    </div>
                </main>
            </>
        )
    }
}
export default CouponDetails

import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Navbar from '../../components/navbar'
import Search from '../../components/search'

import axios from 'axios'

const queryString = require('query-string');



const Listing = (props) => {
    const [list, setList] = useState([])
    const [paging, setPaging] = useState(true)
    const [loading, setLoading] = useState(true)
    const [topScroll, setTopScroll] = useState(0)
    const [filterOption, setFilterOption] = useState(false)
    const [pageNum, setPageNum] = useState(1)
    const [pageLoader, setPageLoader] = useState(false)
    const [filterForm, setFilterForm] = useState(false)
    const [search, setSearch] = useState('')
    const [location, setLocation] = useState('')
    const [suggetionTitle, setSuggetionTitle] = useState([])
    const [suggetionLocation, setSuggetionLocation] = useState([])
    const [sortby, setSortby] = useState('distance')
    const [showNav, setShowNav] = useState(false)
    const [notfound, setNotfound] = useState(false)
    const [lat, setLat] = useState('40.78239')
    const [lng, setLng] = useState('-74.07114')
    const handleNavbar = () => {
        setShowNav(!showNav)
    }
    const handleFilterOption = () => {
        setFilterOption(!filterOption)
    }

    // const handleNotFound = () => {}
    const handleLoadMore = (page, search_title, search_location, sort_by) => {
        setPageLoader(true)
        setTimeout(()=>{
            axios.get(`https://relycircle.com/api/listings/search?page=${page}&search_title=${search_title}&sort=${sort_by}&search_location=${search_location}&direction=asc&lat=${lat}&lng=${lng}`)
            .then(res => {
                setList(list.concat(res.data.listings))
                setPaging(res.data.paging.nextPage)
                setPageNum(pageNum + 1)
                setPageLoader(false)
            })
        },10)
    }
    const fetchData = (page, search_title, search_location, sort_by) => {
        setLoading(true)
        axios.get(`https://relycircle.com/api/listings/search?page=${page}&search_title=${search_title}&sort=${sort_by}&search_location=${search_location}&direction=asc&lat=${lat}&lng=${lng}`)
        .then(res => {
            // console.log(res.data)
            setList(res.data.listings)
            setPaging(res.data.paging ? res.data.paging.nextPage : '')
            setLoading(false)
        }) 
    }

    const onScroll = () => {
        setTopScroll(window.pageYOffset)
        // let listingBox = document.getElementById('listing-main')
        // if((window.pageYOffset + window.innerHeight) > (listingBox.offsetTop + listingBox.clientHeight - 200)){
        //     handleLoadMore()
        // }
        // console.log('window - ', window.pageYOffset + window.innerHeight, 'box - ', listingBox.offsetTop + listingBox.clientHeight)
        
    }
    const handleSearchTitle = (e) => {
        setSearch(e.target.value)
        if(!e.target.value == ''){
            axios.get(`https://relycircle.com/api/listings/keywordSuggestions/${e.target.value}`)
            .then(res => {
                // console.log(res.data)
                setSuggetionTitle(res.data.suggestions)
                setLoading(false)
            })
        } else {
            setSuggetionTitle([])
        }
    }
    const handleSearchSubmit = (e) => {
        e.preventDefault()
        setSuggetionLocation([])
        //setList([])
        setTimeout(()=>{
            fetchData(pageNum, search, location, sortby)
        },300)
        setLoading(false)
        setShowNav(false)
        window.scrollTo(0,0)
        setFilterForm(false)
        window.history.pushState("", "", `/?q=${search}&location=${location}&sort=${sortby}`)
    }
    const handleSearchTitleSelect = (item) => {
        setSearch(item.value)
        if(item.location){
            setLocation(item.location)
            setTimeout(()=>{
                document.getElementById('handle-search').click()
            },50)
        }
        setSuggetionTitle([])
        var locationInput = document.getElementById('input-location')
        if(locationInput){
            locationInput.focus()
        }
    }

    const handleSearchLocation = (e) => {
        setLocation(e.target.value)
        if(!e.target.value == ''){
            axios.get(`https://relycircle.com/api/listings/locationSuggestion/${e.target.value}`)
            .then(res => {
                // console.log(res.data)
                setSuggetionLocation(res.data.suggestions)
                setLoading(false)
            })
        } else {
            setSuggetionLocation([])
        }
    }

    const handleSearchLocationSelect = (item) => {
        setLoading(true)
        setLocation(item.value)
        setSuggetionLocation([])
        //setList([])
        setTimeout(()=>{
            fetchData(pageNum, search, item.value, sortby)
        },300)
        setLoading(false)
        window.scrollTo(0,0)
        setFilterForm(false)
    }

    const handleSort = (sort_by) => {
        setLoading(true)
        setFilterOption(false)
        setSortby(sort_by)
        setTimeout(()=>{
            fetchData(pageNum, search, location, sort_by)
        },300)
        window.history.pushState("", "", `/?q=${search}&location=${location}&sort=${sort_by}`)
    }

    const handleFilterForm = () => {
        setFilterForm(!filterForm)
    }
    function dateFormat(date){
        var newDate = date.split('-', 3)
        var year = newDate[0]
        var month = newDate[1]
        var day = newDate[2].split(' ', 1)
        return day+'/'+month+'/'+year
    }
    function getLocation(){
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(showPosition)
            fetchData(pageNum, search, location, sortby)
        } else {
           setLocation('07094') 
           fetchData(pageNum, search, location, sortby)
        }
    }
    function showPosition(position) {
        // console.log("Latitude: ", position.coords.latitude)
        // console.log("Longitude: ", position.coords.longitude)
        setLat(position.coords.latitude)
        setLng(position.coords.longitude)
      }
    useEffect(() => {        
        getLocation()
        let searchUrl = window.location.search
        //window.history.pushState("", "", searchUrl.replace(/%20/g, "+"))
        const parsed = queryString.parse(searchUrl);
        //console.log('location - ', parsed);
        document.addEventListener('scroll', onScroll);
        if(window.location.search) {
            setSearch(parsed.q)
            setLocation(parsed.location)
            fetchData(pageNum, parsed.q, parsed.location, parsed.sort)     
        } else {
            fetchData(pageNum, search, location, sortby) 
        }               
    },[]);
    return(
        <>  
            {
                showNav ? topScroll > 50 ? (
                    <>
                    <nav className="navbar-full">
                        <div className="navbar-inner">
                            
                            <form className="search-form" onSubmit={handleSearchSubmit}>
                                <div className="search-panel">
                                    <div className="inner">
                                        <div className="search-box flex">
                                            <div className="search-fields flex flex-auto">
                                                <div className="search-col flex-1">
                                                    <div className="filter-input-box"><input type="text" placeholder="Find" autoComplete="off" value={search} onChange={handleSearchTitle} /></div>
                                                </div>
                                                <div className="search-col flex-1">
                                                    <div className="filter-input-box"><input type="text" placeholder="Near" autoComplete="off" value={location} onChange={handleSearchLocation} /></div>
                                                </div>
                                            </div>
                                            <div className="search-button">
                                                <button type="submit" className="btn-search" id="handle-search"><img src={require('../../assets/images/search.svg')} /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="suggestions">
                                        <ul className="suggetion-list">
                                            {
                                                suggetionTitle.map((item,i)=>{
                                                    return (
                                                        <li key={i} onClick={() => handleSearchTitleSelect(item)}>{item.value} <span>{item.location}</span></li>
                                                    )
                                                })
                                            }
                                            {
                                                suggetionLocation.map((item,i)=>{
                                                    return (
                                                        <li key={i} onClick={() => handleSearchLocationSelect(item)}>{item.value}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {/* <Link to={`/?search=${search}=location=${location}`} id="RedirectPage"></Link> */}
                            </form>
                            
                            <Navbar />
                        </div>
                    </nav>
                    <div className="page-overlay" onClick={handleNavbar}></div>
                    </>
                ) : null : null
            }
            <div className={(topScroll > 50) ? ('nav-full-panel fixed') : 'nav-full-panel'}>
                <div className="nav-filter-top">
                    <div className="filter-basic">
                        <div className="inner">
                            <div className="filter-main-box flex">
                                <div className="filter-left">
                                    <span className="filter-action" onClick={handleFilterOption}><i className="icon-filter"></i></span>
                                    {
                                        filterOption ? (
                                            <>
                                            <ul className="filter-dropdown">
                                                <li className="title">Sort By</li>
                                                <li className={sortby == 'review_count' ? 'active' : ''} onClick={() => handleSort('review_count')}>Most Recommended</li>
                                                <li className={sortby == 'distance' ? 'active' : ''} onClick={() => handleSort('distance')}>Nearby</li>
                                            </ul>
                                            <div className="page-overlay" onClick={handleFilterOption}></div>
                                            </>
                                        ) : null
                                    }
                                </div>
                                <div className="filter-right flex-auto" onClick={handleFilterForm}>
                                    <div className="filter-input-box">
                                        <input type="text" value={search != '' ? search : 'Everything'} />
                                    </div>
                                    <span className="location-action">{location != '' ? location : 'current location'}</span>
                                </div>
                            </div>    
                        </div>
                    </div>
                    {
                        filterForm ? (
                            <div className="filter-form">
                                <form className="filter-form-inner flex" onSubmit={handleSearchSubmit}>
                                    <div className="filter-form-left flex-auto">
                                        <div className="inner filter-inner">
                                            <div className="flex">
                                                <div className="filter-form-left flex-auto">
                                                    <div className="filter-input-box flex"><i className="filter-icon"><img src={require('../../assets/images/search-white.svg')} /> </i> <input type="text" className="filter-input" autoComplete="off" tabIndex="1" id="input-title" placeholder="What are you looking for?" value={search} onChange={handleSearchTitle} /> 
                                                    <span className="input-cancel" onClick={() => {
                                                        setSearch('')
                                                        setSuggetionTitle([])
                                                        document.getElementById('input-title').focus()
                                                    }}></span>
                                                    </div>
                                                </div>
                                                <div className="filter-form-cancel"><span className="cancel-filter" onClick={handleFilterForm}>CANCEL</span></div>
                                            </div>
                                            <div className="suggestions suggestion-title">
                                                <ul className="suggetion-list">
                                                    {
                                                        suggetionTitle.map((item,i)=>{
                                                            return (
                                                                <li key={i} onClick={() => handleSearchTitleSelect(item)}>{item.value} <span>{item.location}</span></li>
                                                            )
                                                        })
                                                    }
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="inner filter-inner">
                                            <div className="flex">
                                                <div className="filter-form-left flex-auto">
                                                    <div className="filter-input-box flex"><i className="filter-icon"><img src={require('../../assets/images/location-white.svg')} /></i> <input type="text" className="filter-input" autoComplete="off" placeholder="Current location" id="input-location" tabIndex="2"  value={location} onChange={handleSearchLocation} /> 
                                                    <span className="input-cancel" onClick={() => {
                                                        setLocation('')
                                                        setSuggetionLocation([])
                                                        document.getElementById('input-location').focus()
                                                    }}></span>
                                                    </div>
                                                </div>
                                                <div className="filter-form-cancel"></div>
                                            </div>
                                            <div className="suggestions">
                                                <ul className="suggetion-list">
                                                    {
                                                        suggetionLocation.map((item,i)=>{
                                                            return (
                                                                <li key={i} onClick={() => handleSearchLocationSelect(item)}>{item.value}</li>
                                                            )
                                                        })
                                                    }                                                    
                                                </ul>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <button type="submit" id="handle-search" className="handle-search">search</button>
                                </form> 
                            </div>
                        ) : null
                    }
                </div>
                <nav className="nav-full">
                    <div className="inner flex">
                        <div className="logo"><img src={require('../../assets/images/logo.svg')} alt="RelyCircle" /></div>
                        <div className="flex-auto">
                            <div className="search-toggle" onClick={handleNavbar}>
                                <i className="icon-search">
                                    {showNav ? <img src={require('../../assets/images/close2.svg')} /> : <img src={require('../../assets/images/menu.svg')} /> }
                                </i>
                            </div>
                        </div>
                    </div>
                </nav>    
            </div>
            <main className="main-full">
                <div className="listing-outer" id="listing-main">
                    {
                        loading ? (<div className="loader"></div>) : (
                            list.map((item,i) => {
                                return(
                                    <div className="listing-item" key={item.Listing.id} key={i}>
                                        <Link to={'business-detail/'+item.Listing.id+'?userId=0'} className="listing-img" style={{backgroundImage: 'url(https://relycircle.com'+item.Listing.default_image_thumbs.medium+')'}}>
                                            <div className="listing-title-box">
                                                <div className="listing-title-left">
                                                    <div className="title-box">
                                                        <h4>{item.Listing.business_name}</h4>
                                                        <div className="location"><i className="icon-location"></i> {item.Listing.zip_code_info.city+', '+item.Listing.zip_code_info.state}</div>
                                                    </div>
                                                </div>
                                                <div className="listing-title-right"><i className="icon-info">
                <svg id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m256 0c-141.159 0-256 114.841-256 256s114.841 256 256 256 256-114.841 256-256-114.841-256-256-256zm0 482c-124.617 0-226-101.383-226-226s101.383-226 226-226 226 101.383 226 226-101.383 226-226 226z"/><path d="m256 96.4c-25.989 0-47.133 21.144-47.133 47.133s21.144 47.134 47.133 47.134 47.133-21.145 47.133-47.134-21.144-47.133-47.133-47.133zm0 64.267c-9.447 0-17.133-7.687-17.133-17.134s7.686-17.133 17.133-17.133 17.133 7.686 17.133 17.133-7.686 17.134-17.133 17.134z"/><path d="m256 224.934c-25.989 0-47.133 21.144-47.133 47.133v96.4c0 26.578 21.647 47.133 47.133 47.133 25.989 0 47.133-21.144 47.133-47.133v-96.4c0-26.578-21.647-47.133-47.133-47.133zm17.133 143.533c0 9.447-7.686 17.133-17.133 17.133-9.351 0-17.133-7.537-17.133-17.133v-96.4c0-9.447 7.686-17.133 17.133-17.133 9.351 0 17.133 7.537 17.133 17.133z"/></g></svg></i></div>
                                            </div>
                                        </Link>
                                        <div className="listing-reviews">
                                            <ul className="reviews-listing">
                                                {
                                                    item.Listing.recommendationUsers ? (
                                                        item.Listing.recommendationUsers.map((review,index) => {
                                                            if(index >= 4) return null
                                                            return(
                                                                <li key={index}>
                                                                    <Link to={'business-detail/'+item.Listing.id+'?userId='+review.User.id} className="reviews-item flex">
                                                                        <div className="review-left">
                                                                            <div className="thumb-box">
                                                                                <div className="thumb">
                                                                                    {review.User.avatar_thumbs.small ? (
                                                                                    <img src={'https://relycircle.com'+review.User.avatar_thumbs.small} alt={review.User.first_name} />
                                                                                    ) : (
                                                                                    <img src={require('../../assets/images/user-default.jpg')} alt={review.User.first_name} />
                                                                                    )} 
                                                                                </div>
                                                                                <span className="add-icon-box"><i className="icon-add">+</i></span>     
                                                                            </div>
                                                                        </div>
                                                                        <div className="review-info">
                                                                            <div className="review-name">{review.User.first_name+' '+review.User.last_name.substring(0, 1)+'.'}</div>
                                                                            <div className="review-location">
                                                                                {review.User.zip_code ? (
                                                                                    review.User.zip_code.city+', '+review.User.zip_code.state
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="review-date">{dateFormat(review.Review.created)}</div>
                                                                        </div>    
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })
                                                    ) : ('')
                                                }
                                                {
                                                    (item.Listing.recommendationUsers.length > 4) ? (
                                                        <li className="more-review-count"><Link to={'business-detail/'+item.Listing.id+'?userId=0'}>+{item.Listing.recommendationUsers.length - 4}</Link></li>
                                                    ) : null
                                                }
                                                
                                            </ul>
                                        </div>
                                    </div>
                                )
                            })
                        )
                    }
                {
                    list.length ? '' : <div className="no-business">No business found</div>
                }
                </div>
                <div className="load-more">
                    {
                        paging ? pageLoader ? <span className="paing-loader"></span> : <span className="btn-main" onClick={() => handleLoadMore(pageNum + 1, search, location, sortby)}>Load more</span> : null
                    }
                </div>
            </main>
        </>
    )
}

export default Listing
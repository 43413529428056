import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Navbar from '../../components/navbar'
import Search from '../../components/search'
import axios from 'axios'

function dateFormat(date){
    var newDate = date.split('-', 3)
    var year = newDate[0]
    var month = newDate[1]
    var day = newDate[2].split(' ', 1)
    return day+'/'+month+'/'+year
}

class Profile extends Component{
    state = {
        user: '',
        loading: true,
        reviews: [],
        fbUser: '',
        showNav: false,
    }
    handleNavbar = () => {
        this.setState({
            showNav: !this.state.showNav
        })
    }
    handleLogout = () => {
        localStorage.setItem('auth', undefined);
        localStorage.setItem('fb_user', undefined);
        document.getElementById("loggedinRedirect").click()
    }
    
    componentDidMount = () => {
        if(!localStorage.getItem('auth')){
            document.getElementById("loggedinRedirect").click()            
        }
        if(localStorage.getItem('fb_user')){
            this.setState({
                fbUser: JSON.parse(localStorage.getItem('fb_user'))
            })             
        }
        let userToken =  localStorage.getItem('auth')
        axios.get('https://relycircle.com/api/users/myAccount', {
            headers: {
                'X-Auth-Token': `${userToken}`
            }
        }).then((res) => {
            this.setState({
                user: res.data.User
            })
            console.log(res)
            axios.get(`https://relycircle.com/api/reviews/users/${this.state.user.id}`)
            .then(res => {
                this.setState({
                    reviews: res.data,
                    loading: false
                })
                //
            })
        }).catch((res) => {
            console.log(res)
        });
    }
    render(){
        let user = this.state.user
        let reviews = this.state.reviews.reviews
        let fbUser = this.state.fbUser
        return(
            <>  
            {
                this.state.showNav ? (
                    <>
                    <nav className="navbar-full">
                        <div className="navbar-inner">
                            <Search />
                            <Navbar />
                        </div>
                    </nav>
                    
                    <div className="page-overlay" onClick={this.handleNavbar}></div>
                    </>
                ) : null
            }
                <main className="main-full">
                    <Link to={'/login'} id="loggedinRedirect"></Link>
                    
                    <div className="detail-header coupons-header">
                        <Link to={'/'} className="back-btn"><i className="icon-back"></i></Link>
                        <div className="search-btn" onClick={this.handleNavbar}>
                            <i className="icon-nav">
                                {this.state.showNav ? <img src={require('../../assets/images/close2.svg')} /> : <img src={require('../../assets/images/menu.svg')} /> }
                            </i>
                        </div>
                        <div className="title-box"><h4>MY ACCOUNT</h4> </div>
                    </div>

                    <div className="profile-page-container">
                        <div className="profile-info">
                            {
                                fbUser.graphDomain == "facebook" ? (
                                    <div className="inner">
                                        <div className="profile-info-inner flex items-center">
                                            <div className="profile-thumb"><img src={fbUser.picture.data.url} alt={fbUser.name} /></div>
                                            <div className="profile-thumb-info flex-auto">
                                                <div className="username">{fbUser.name} </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="inner">
                                        <div className="profile-info-inner flex items-center">
                                            <div className="profile-thumb"><img src={'http://relycircle.com'+user.avatar} alt={user.display_name} /></div>
                                            <div className="profile-thumb-info flex-auto">
                                                <div className="username">{user.display_name} </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="detail-meta flex profile-meta">
                                <div className="detail-meta-item"><span>{user.review_count ? user.review_count : '0'}</span> Recommendations </div>
                                <div className="detail-meta-item"><span>{user.user_following_count ? user.user_following_count : '0'}</span> In Circle</div>
                                <div className="detail-meta-item"><span>{user.user_follower_count ? user.user_follower_count : '0'}</span> Followers </div>
                            </div>
                            <div className="total-rewards">
                                <div className="heading2">Total Cash Rewards = $0</div>
                            </div>
                        </div>
                        <div className="reviews-box">
                            {
                                this.state.loading ? (<div className="loader2"></div>) : (
                                    <ul className="reviews-listing-box">
                                        {
                                            reviews.length ? (
                                                reviews.map((review,i)=>{
                                                    return(
                                                        <li key={i}>
                                                            <div className="review-item">
                                                                <div className="review-top flex items-center">
                                                                    <div className="profile-thumb"><img src={'http://relycircle.com'+review.Listing.default_image} alt={review.Listing.business_name} /></div>
                                                                    <div className="profile-thumb-info flex-auto">
                                                                        <div className="username">{review.Listing.business_name} </div>
                                                                        <div className="username-location">{review.Listing.zip_code_info.county + ', ' + review.Listing.zip_code_info.state} </div>
                                                                    </div>
                                                                </div>
                                                                <div className="review-body">
                                                                    <p>{review.Review.content} </p>
                                                                    <div className="review-meta flex">
                                                                        <div className="flex-auto">{dateFormat(review.Review.created)}</div>
                                                                        <div className=""></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            ) : (
                                                <div className="no-business no-reviews">No Reviews</div>
                                            )
                                        }
                                    </ul>
                                )
                            }
                        </div>
                    </div>
                </main>
            </>
        )
    }
}

export default Profile
import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'

const Search = (props) => {
    const [search, setSearch] = useState('')
    const [location, setLocation] = useState('')
    const [suggetionTitle, setSuggetionTitle] = useState([])
    const [suggetionLocation, setSuggetionLocation] = useState([])
    

    const handleSearchTitle = (e) => {
        setSearch(e.target.value)
        if(!e.target.value == ''){
            axios.get(`https://relycircle.com/api/listings/keywordSuggestions/${e.target.value}`)
            .then(res => {
                // console.log(res.data)
                setSuggetionTitle(res.data.suggestions)
            })
        } else {
            setSuggetionTitle([])
        }
    }

    const handleSearchTitleSelect = (item) => {
        setSearch(item.value)
        if(item.location){
            setLocation(item.location)
            setTimeout(()=>{
                document.getElementById('handle-search').click()
            },50)
        }
        setSuggetionTitle([])
    }

    const handleSearchLocation = (e) => {
        setLocation(e.target.value)
        if(!e.target.value == ''){
            axios.get(`https://relycircle.com/api/listings/locationSuggestion/${e.target.value}`)
            .then(res => {
                // console.log(res.data)
                setSuggetionLocation(res.data.suggestions)
            })
        } else {
            setSuggetionLocation([])
        }
    }

    const handleSearchLocationSelect = (item) => {
        setLocation(item.value)
        setTimeout(()=>{
            document.getElementById('handle-search').click()
        },50)
        setSuggetionLocation([])
    }
    const handleSearchSubmit = (e) => {
        e.preventDefault()
        document.getElementById('RedirectPage').click()
    }
    return(
        <>  
            <form className="search-form" onSubmit={handleSearchSubmit}>
                <div className="search-panel">
                    <div className="inner">
                        <div className="search-box flex">
                            <div className="search-fields flex flex-auto">
                                <div className="search-col flex-1">
                                    <div className="filter-input-box"><input type="text" placeholder="Find" autoComplete="off" value={search} onChange={handleSearchTitle} /></div>
                                </div>
                                <div className="search-col flex-1">
                                    <div className="filter-input-box"><input type="text" placeholder="Near" autoComplete="off" value={location} onChange={handleSearchLocation} /></div>
                                </div>
                            </div>
                            <div className="search-button">
                                <button type="submit" className="btn-search" id="handle-search"><img src={require('../assets/images/search.svg')} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="suggestions">
                        <ul className="suggetion-list">
                            {
                                suggetionTitle.map((item,i)=>{
                                    return (
                                        <li key={i} onClick={() => handleSearchTitleSelect(item)}>{item.value} <span>{item.location}</span></li>
                                    )
                                })
                            }
                            {
                                suggetionLocation.map((item,i)=>{
                                    return (
                                        <li key={i} onClick={() => handleSearchLocationSelect(item)}>{item.value}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <Link to={`/?q=${search}&location=${location}&sort=`} id="RedirectPage"></Link>
            </form>
        </>
    )
}
export default Search
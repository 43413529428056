import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Details from './details'
import GiftDetails from './gift-details'
import OfferDetails from './offer-details'
import axios from 'axios'


class Coupon extends Component{
    state = {
        couponsData: '',
        couponDetails: '',
        listing: '',
        loading: true,
        couponDetailsPanel: '',
        // tabs: [
        //     {id:'1', name: 'Coupons', key: 'Coupon'},
        //     {id:'2', name: 'Gift Certificate', key: 'GiftCertificate'},
        //     {id:'2', name: 'TryUsOffers', key: 'Offer'}
        // ]
        amount: 1,
        tab: 0,
        reviewId: 0,
    }
    handleTab = (id) => {
        this.setState({
            tab: id
        })
        window.scrollTo(0,0)
    }
    handleCouponDetails = (item) => {
        this.setState({
            couponDetailsPanel: item
        })
    }
    handleClose = () => {
        this.setState({
            couponDetailsPanel: ''
        })
    }
    handleAmount = (id) => {
        this.setState({
            amount: id
        })
    }
    componentDidMount = () => {
        this.setState({
            reviewId: window.location.search ? window.location.search.split('=')[1] : 0,
        })
        axios.get(`https://relycircle.com/api/offers/list/${this.props.match.params.id}`)
        .then(res => {
            this.setState({
                couponsData: res.data.offersList,
                listing: res.data.listing,
                couponDetails: res.data,
                loading: false
            })
            if(this.state.couponsData.Coupon.length){
                this.setState({
                    tab: 1
                })
            } else if(this.state.couponsData.GiftCertificate.length){
                this.setState({
                    tab: 2
                })
            } else {
                this.setState({
                    tab: 3
                })
            }
        })
    }
    render(){
        let coupons = this.state.couponsData
        let listing = this.state.listing.Listing
        console.log('data[Coupon][id] ', coupons.Coupon)
        return(
            <main className="main-full bg-grey">
                {
                    this.state.loading ? (<div className="loader"></div>) : (
                        <>
                            <div className="detail-header coupons-header">
                                <span onClick={() => window.history.back()} className="back-btn"><i className="icon-back"></i></span>
                                {/* <div className="search-btn"><i className="icon-nav"><img src={require('../../assets/images/menu.svg')} /></i></div> */}
                                <div className="title-box">
                                    <h4>{listing.title}</h4>
                                </div>
                            </div>
                            <div className="page-tab-full">
                                <ul className="page-tab coupon-page-tab" id="pageTab" data-error="Sorry! No offers available as of now. Please check back later">
                                    {this.state.couponsData.Coupon.length ? <li className={this.state.tab == 1 ? "active" : ""} onClick={() => this.handleTab(1)}><span>Coupons</span></li> : null}
                                    {this.state.couponsData.GiftCertificate.length ? <li className={this.state.tab == 2 ? "active" : ""} onClick={() => this.handleTab(2)}><span>Gift Certificate</span></li> : null}
                                    {this.state.couponsData.Offer.length ? <li className={this.state.tab == 3 ? "active" : ""} onClick={() => this.handleTab(3)}><span>TryUsOffers</span></li> : null}
                                </ul>
                            </div>
                            
                            <div className={(this.state.couponDetailsPanel != '') ? "coupons-full coupon-item-hide" : "coupons-full"}>
                                {this.state.couponsData.Coupon.length ? (                                    
                                        this.state.tab == 1 ? (
                                            coupons.Coupon.map((item,i) => {
                                                return (
                                                    <>
                                                        <div className="coupon-item coupon-listing-item" key={i} onClick={() => this.handleCouponDetails(item)}>
                                                            <div className="detail-header coupons-header">
                                                                <Link to={'/'} className="back-btn"><i className="icon-back"></i></Link>
                                                                <div className="title-box">
                                                                    <h4>{listing.title}</h4>
                                                                </div>
                                                            </div>
                                                            <div class="listing-img coupon-img" style={{backgroundImage: 'url(https://relycircle.com'+item.Coupon.image_thumbs.medium+')'}}></div>
                                                            <div className="coupon-info">{item.Coupon.description}</div>
                                                        </div>
                                                        {
                                                            (this.state.couponDetailsPanel == item) ? (
                                                                <div className="detail-full-details">
                                                                    <Details details={this.state.couponDetails} listingId={listing.id} referCode={this.state.reviewId} item={item} close={this.handleClose} />
                                                                </div>
                                                            ) : ('')
                                                        }
                                                    </>
                                                )
                                            })
                                        ) : null
                                    ) : null 
                                }
                                {this.state.couponsData.GiftCertificate.length ? (
                                    this.state.tab == 2 ? (
                                        this.state.couponsData.GiftCertificate.length ? (
                                            <>
                                            <div className="coupon-item coupon-listing-item">
                                                <div className="detail-header coupons-header">
                                                    <Link to={'/'} className="back-btn"><i className="icon-back"></i></Link>
                                                    <div className="title-box">
                                                        <h4>{listing.title}</h4>
                                                    </div>
                                                </div>
                                                <div class="listing-img coupon-img" style={{backgroundImage: 'url(https://relycircle.com'+this.state.couponsData.GiftCertificate[0].GiftCertificate.image_thumbs.medium+')'}}></div>
                                                <div className="coupon-info amount-section">
                                                    <div className="heading1">Select Amount</div>
                                                    <ul className="amount-list">
                                                        <li className={this.state.amount == 1 ? "active" : ""} onClick={() => this.handleAmount(1)}>$ 50</li>    
                                                        <li className={this.state.amount == 2 ? "active" : ""} onClick={() => this.handleAmount(2)}>$ 100</li>    
                                                        <li className={this.state.amount == 3 ? "active" : ""} onClick={() => this.handleAmount(3)}>$ 200</li>    
                                                        <li className={this.state.amount == 4 ? "active" : ""} onClick={() => this.handleAmount(4)}>$ 500</li>    
                                                    </ul>    
                                                </div>
                                            </div>
                                            <div className="brief-full gift-desc-box">
                                                <div className="inner">
                                                    <div className="brief">
                                                        {this.state.couponsData.GiftCertificate[0].GiftCertificate.terms}
                                                    </div>
                                                    <div className="title-box"><h4>{listing.title}</h4></div>
                                                </div>
                                            </div>
                                            <div className="detail-contact">
                                                <div className="inner">
                                                    <ul className="detail-contact-list">
                                                        <li>
                                                            <div className="contact-item flex">
                                                                <div className="contact-item-left flex-auto"><i className="icon-location"></i> {this.state.listing.Listing.address_1+' '+this.state.listing.Listing.zip_code_info.city+', '+this.state.listing.Listing.zip_code_info.state+' '+this.state.listing.Listing.zip_code_info.zip_code}</div>
                                                                <div className="contact-item-right"><a onClick={() => this.handleMapLink(this.state.listing.Listing.lat, this.state.listing.Listing.lng)} className="link">Go To Maps <i className="icon-link"></i></a></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="contact-item flex">
                                                                <div className="contact-item-left flex-auto contact-webiste-url"><i className="icon-website"></i> {this.state.listing.ListingContactInformation.website}</div>
                                                                <div className="contact-item-right"><a href={this.state.listing.ListingContactInformation.website} target="_blank" className="link">Go To Website <i className="icon-link"></i></a></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="contact-item flex">
                                                                <div className="contact-item-left flex-auto"><i className="icon-phone"></i> {this.state.listing.ListingContactInformation.phone}</div>
                                                                <div className="contact-item-right"><a href={"tel:"+this.state.listing.ListingContactInformation.phone} className="link">Call Phone <i className="icon-link"></i></a></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="btn-space"></div>
                                            <div className="save-button-full"><a href="https://z8x94.app.goo.gl/u9DC" target="_blank" className="btn-main">Make Purchase</a></div>
                                            </>
                                        ) : null
                                    ) : null
                                ) : null
                                }
                                {this.state.couponsData.Offer.length ? (
                                    this.state.tab == 3 ? (
                                            this.state.couponsData.Offer.map((item,i) => {
                                                return (
                                                    <>
                                                        <div className="coupon-item coupon-listing-item" key={i} onClick={() => this.handleCouponDetails(item)}>
                                                            <div className="detail-header coupons-header">
                                                                <Link to={'/'} className="back-btn"><i className="icon-back"></i></Link>
                                                                <div className="title-box">
                                                                    <h4>{listing.title}</h4>
                                                                </div>
                                                            </div>
                                                            <div class="listing-img coupon-img" style={{backgroundImage: 'url(https://relycircle.com'+item.Offer.image_thumbs.medium+')'}}></div>
                                                            <div className="coupon-info">{item.Offer.terms}</div>
                                                        </div>
                                                        {
                                                            (this.state.couponDetailsPanel == item) ? (
                                                                <div className="detail-full-details">
                                                                    <OfferDetails details={this.state.couponDetails} item={item} close={this.handleClose} />
                                                                </div>
                                                            ) : ('')
                                                        }
                                                    </>
                                                )
                                            })
                                    ) : null
                                ) : null }
                                
                            </div>
                        </>
                    )
                }
            </main>
        )
    }
}

export default Coupon
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import renderHTML from 'react-render-html'


class GiftDetails extends Component{
    state = {
        detailsData: '',
        readMore: true,
        loading: true
    }
    handleReadMore = () => {
        this.setState({
            readMore: !this.state.readMore
        })
    }
    componentDidMount = () => {
        axios.get(`https://relycircle.com/api/listings/information/53`)
        .then(res => {
            this.setState({
                detailsData: res.data.listing,
                loading: false
            })
        })
    }
    handleMapLink = (lat,lng) => {
        if( (navigator.platform.indexOf("iPhone") != -1) 
            || (navigator.platform.indexOf("iPod") != -1)
            || (navigator.platform.indexOf("iPad") != -1))
             window.open("maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination="+lat+","+lng);
        else
             window.open("https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination="+lat+","+lng);
    }
    render(){
        let details = this.props.details.listing
        console.log('details ', details)
        console.log('coupon detail - ', this.state.detailsData.Listing)
        return(
            <>
                <div className="detail-header coupons-header">
                    <span onClick={this.props.close} className="back-btn"><i className="icon-back"></i></span>
                    {/* <div className="search-btn"><i className="icon-nav"><img src={require('../../assets/images/menu.svg')} /></i></div> */}
                    <div className="title-box"><h4>Gift Certificate</h4></div>
                </div>
                <div className="detail-full">
                    <div className="coupon-item">
                        <div class="listing-img coupon-img" style={{backgroundImage: 'url(https://relycircle.com'+this.props.item.GiftCertificate.image_thumbs.medium+')'}}></div>
                        {
                            this.props.item.GiftCertificate.description ? <div className="coupon-info">{this.props.item.GiftCertificate.description}</div> : null
                        }
                        
                    </div>
                    <div className="brief-full">
                        <div className="inner">
                            <div className="brief">
                                {renderHTML(this.props.item.GiftCertificate.terms.replace("\r\n", '<br />'))}
                            </div>
                            <div className="title-box"><h4>{details.Listing.title}</h4></div>
                        </div>
                    </div>
                    <div className="detail-contact">
                        <div className="inner">
                            <ul className="detail-contact-list">
                                <li>
                                    <div className="contact-item flex">
                                        <div className="contact-item-left flex-auto"><i className="icon-location"></i> {details.Listing.address_1+' '+details.Listing.zip_code_info.city+', '+details.Listing.zip_code_info.state+' '+details.Listing.zip_code_info.zip_code}</div>
                                        <div className="contact-item-right"><a onClick={() => this.handleMapLink(details.Listing.lat, details.Listing.lng)} className="link">Go To Maps <i className="icon-link"></i></a></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="contact-item flex">
                                        <div className="contact-item-left flex-auto contact-webiste-url"><i className="icon-website"></i> {details.ListingContactInformation.website}</div>
                                        <div className="contact-item-right"><a href={details.ListingContactInformation.website} target="_blank" className="link">Go To Website <i className="icon-link"></i></a></div>
                                    </div>
                                </li>
                                <li>
                                    <div className="contact-item flex">
                                        <div className="contact-item-left flex-auto"><i className="icon-phone"></i> {details.ListingContactInformation.phone}</div>
                                        <div className="contact-item-right"><a href={"tel:"+details.ListingContactInformation.phone} className="link">Call Phone <i className="icon-link"></i></a></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="save-button-full"><a href="https://z8x94.app.goo.gl/u9DC" target="_blank" className="btn-main">Save</a></div>
            </>
        )
    }
}
export default GiftDetails

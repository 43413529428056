import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import axios from 'axios'
import { render } from '@testing-library/react'
import  LoginWithFacebook from '../login/facebook-login';

class Register extends Component {
    state = {
        email: '',
        first_name: '',
        last_name: '',
        zip_code_id: '',
        password: '',
        avatar: ''
    }

    handleFname = (e) => {
        this.setState({
            first_name: e.target.value
        })
        if(!e.target.value == '' && !this.state.password == '' && !this.state.last_name == '' && !this.state.email == '' && !this.state.zip_code_id == ''){
            this.setState({
                activeSubmit: true
            })
        } else {
            this.setState({
                activeSubmit: false
            })
        }
    }
    handleLname = (e) => {
        this.setState({
            last_name: e.target.value
        })
        if(!e.target.value == '' && !this.state.password == '' && !this.state.first_name == '' && !this.state.email == '' && !this.state.zip_code_id == ''){
            this.setState({
                activeSubmit: true
            })
        } else {
            this.setState({
                activeSubmit: false
            })
        }
    }

    handleEmail = (e) => {
        this.setState({
            email: e.target.value
        })
        if(!e.target.value == '' && !this.state.first_name == '' && !this.state.last_name == '' && !this.state.password == '' && !this.state.zip_code_id == ''){
            this.setState({
                activeSubmit: true
            })
        } else {
            this.setState({
                activeSubmit: false
            })
        }        
    }
    handlePassword = (e) => {
        this.setState({
            password: e.target.value
        })
        if(!e.target.value == '' && !this.state.first_name == '' && !this.state.last_name == '' && !this.state.email == '' && !this.state.zip_code_id == ''){
            this.setState({
                activeSubmit: true
            })
        } else {
            this.setState({
                activeSubmit: false
            })
        }
    }
    handleZipcode = (e) => {
        this.setState({
            zip_code_id: e.target.value
        })
        if(!e.target.value == '' && !this.state.first_name == '' && !this.state.last_name == '' && !this.state.email == '' && !this.state.password == ''){
            this.setState({
                activeSubmit: true
            })
        } else {
            this.setState({
                activeSubmit: false
            })
        }
    }

    handleAvatar = (e) => {
        this.setState({
            avatar: URL.createObjectURL(e.target.files[0])
        })
    }
    handleSubmit = (e) => {
        e.preventDefault()
        if(!this.state.first_name == '' && !this.state.last_name == '' && !this.state.email == '' && !this.state.password == '' && !this.state.zip_code_id == ''){
            this.setState({
                activeSubmit: true
            })
            var formdata = new FormData();
            formdata.append("data[User][email]", this.state.email);
            formdata.append("data[User][first_name]", this.state.first_name);
            formdata.append("data[User][last_name]", this.state.last_name);
            formdata.append("data[User][zip_code_id]", this.state.zip_code_id);
            formdata.append("data[User][password]", this.state.password);
            formdata.append("data[User][avatar]", this.state.avatar);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://relycircle.com/api/users/register", requestOptions)
            .then(response => response.text())
            .then(result => {
                var resp = JSON.parse(result)
                if(resp.User){
                    this.setState({
                        loading: true
                    })
                    console.log(resp.User.auth_token.token)
                    localStorage.setItem('auth', resp.User.auth_token.token)
                    document.getElementById("loggedinRedirect").click()
                } else {
                    var zipcode_msg
                    var email_msg
                    if(resp.errors.zip_code_id){
                        zipcode_msg = resp.errors.zip_code_id
                    } else {
                        zipcode_msg = ''
                    }
                    if(resp.errors.email){
                        email_msg = resp.errors.email
                    } else {
                        email_msg = ''
                    }
                    alert(resp.message + "\r\n" + email_msg + "\r\n" + zipcode_msg)
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(error => console.log('error', error));
        } else {
            this.setState({
                activeSubmit: false,
                loading: false
            })
        }

    }
    componentDidMount = () => {
        if(localStorage.getItem('auth')){
            document.getElementById("loggedinRedirect").click()
        }
    }
    render(){
        return(
            <>  
                <main className="main-full">
                    <div className="login-main-pagel register-main-panel">
                        <div className="login-top">
                            <Link to={'/login'} className="back-btn"><i className="icon-back"></i></Link>
                            <div className="logo-login"><img src={require('../../assets/images/logo.svg')} alt="RelyCircle" /></div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '10px 0' }}>
                            <LoginWithFacebook />
                            <div class="or-divider">OR</div>
                        </div>
                        
                        <div className="register-form-panel">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-item">
                                    <input type="text" className="form-style" name="first_name" value={this.state.first_name} onChange={this.handleFname} placeholder="First Name" />
                                </div>
                                <div className="form-item">
                                    <input type="text" className="form-style" name="last_name" value={this.state.last_name} onChange={this.handleLname} placeholder="Last Name" /> 
                                </div>
                                <div className="form-item"><input type="text" className="form-style" name="email" value={this.state.email} onChange={this.handleEmail} placeholder="E-mail Address" /> </div>
                                <div className="form-item">
                                    <input type="password" className="form-style" name="password" value={this.state.password} placeholder="Password" onChange={this.handlePassword} /> 
                                </div>
                                <div className="form-item"><input type="number" className="form-style" name="zip_code_id" pattern="[0-9]*" inputMode="numeric" placeholder="Zip Code" value={this.state.zip_code_id} onChange={this.handleZipcode} /> </div>
                                <div className="form-item">
                                    <div className="avatar-box" style={{backgroundImage: 'url('+this.state.avatar+')'}}>
                                        <div className="add-avatar">
                                            <input type="file" name="avatar" onChange={this.handleAvatar} />
                                            {this.state.avatar == '' && <span> <strong>+</strong> Photo</span>}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-submit">
                                    <button className="btn-main btn-submit" disabled={!this.state.activeSubmit && true} type="submit">SignUp</button>
                                    <Link to={'/'} id="loggedinRedirect"></Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="login-terms">By using RelyCircle, you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a></div>
                </main>
            </>
        )
    }
}

export default Register
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import FacebookLogin from 'react-facebook-login';

class LoginWithFacebook extends Component{

    responseFacebook = (response) => {
        console.log({ response });
        if (response.accessToken) {
            localStorage.setItem('auth', response.accessToken)
            localStorage.setItem('fb_user', JSON.stringify(response))
            document.getElementById("loggedinRedirect").click()
        }
    }
    componentClicked = (data) => {
        //console.log(data);
    }          
    render(){
        return(
            <>  
                <div className="login-with-outer">
                    <div className="login-with">
                        <FacebookLogin
                        appId="1705944133006726"
                        fields="name,email,picture"
                        disableMobileRedirect
                        onClick={this.componentClicked}
                        callback={this.responseFacebook} />
                        <i className="icon-fb"></i>
                        Log in With Facebook
                    </div>
                </div>
                <Link to={'/'} id="loggedinRedirect"></Link>
            </>
        )
    }
}

export default LoginWithFacebook
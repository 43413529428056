import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import LoginWithFacebook from './facebook-login'

class Login extends Component{
    state = {
        email: '',
        password: '',
        activeSubmit: false,
        loading: false
    }
    handleEmail = (e) => {
        this.setState({
            email: e.target.value
        })
        if(!e.target.value == '' && !this.state.password == ''){
            this.setState({
                activeSubmit: true
            })
        } else {
            this.setState({
                activeSubmit: false
            })
        }        
    }
    handlePassword = (e) => {
        this.setState({
            password: e.target.value
        })
        if(!e.target.value == '' && !this.state.email == ''){
            this.setState({
                activeSubmit: true
            })
        } else {
            this.setState({
                activeSubmit: false
            })
        }
    }
    handleSubmit = (e) => {
        e.preventDefault()
        if(!this.state.email == '' && !this.state.password == ''){
            this.setState({
                activeSubmit: true
            })
            var formdata = new FormData();
            formdata.append("data[User][email]", this.state.email);
            formdata.append("data[User][password]", this.state.password);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://relycircle.com/api/users/login", requestOptions)
            .then(response => response.text())
            .then(result => {
                var resp = JSON.parse(result)
                if(resp.User){
                    this.setState({
                        loading: true
                    })
                    console.log(resp.User.auth_token.token)
                    localStorage.setItem('auth', resp.User.auth_token.token)
                    document.getElementById("loggedinRedirect").click()
                } else {
                    alert(resp.message)
                    this.setState({
                        loading: false
                    })
                }
            })
            .catch(error => console.log('error', error));
        } else {
            this.setState({
                activeSubmit: false,
                loading: false
            })
        }
        
    }
    componentDidMount = () => {
        if(localStorage.getItem('auth')){
            document.getElementById("loggedinRedirect").click()
        }
        if(!this.state.email == '' && !this.state.password == ''){
            this.setState({
                activeSubmit: true
            })
        } else {
            this.setState({
                activeSubmit: false
            })
        }
    }
    render(){
        return(
            <>  
                <main className="main-full">
                    {this.state.loading && <div className="loader"></div>}
                    
                    <div className="login-main-pagel">
                        <div className="login-top">
                            <div className="logo-login"><img src={require('../../assets/images/logo.svg')} alt="RelyCircle" /></div>
                        </div>
                        <LoginWithFacebook />
                        <div className="or-divider">OR</div>
                        <div className="login-form-panel">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-item">
                                    <input type="text" name="email" className="form-style" value={this.state.email} placeholder="E-mail" onChange={this.handleEmail} /> 
                                </div>
                                <div className="form-item">
                                    <input type="password" name="password" className="form-style" value={this.state.password} placeholder="Password" onChange={this.handlePassword} /> 
                                </div>
                                <div className="form-submit">
                                    <button className="btn-main btn-submit" disabled={!this.state.activeSubmit && true} type="submit">Sign In</button>
                                    <Link to={'/'} id="loggedinRedirect"></Link>
                                </div>
                            </form>
                        </div>
                        <div className="login-bottom">Don't have a RelyCircle Account? <Link to={'/register'}>Sign Up</Link></div>
                    </div>
                    <div className="login-terms">By using RelyCircle, you agree to our <a href="https://relycircle.com/termsandconditions" target="_blank">Terms of Service</a> and <a href="https://relycircle.com/privacypolicy" target="_blank">Privacy Policy</a></div>
                </main>
            </>
        )
    }
}

export default Login